import React from "react";
import { IconProps } from "../icon";

export const MessageHeartCircleIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 11.5a8.5 8.5 0 01-11.555 7.934c-.174-.066-.26-.1-.33-.116a.901.901 0 00-.186-.024 2.314 2.314 0 00-.303.021l-5.12.53c-.49.05-.733.075-.877-.013a.5.5 0 01-.234-.35c-.026-.166.09-.382.324-.814l1.636-3.027c.134-.25.202-.374.232-.494a.899.899 0 00.028-.326c-.01-.123-.064-.283-.172-.604A8.5 8.5 0 1121 11.5z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12.496 8.95c-.9-1.04-2.4-1.319-3.527-.368-1.127.951-1.286 2.542-.4 3.667.548.697 1.924 1.97 2.893 2.838.355.319.533.478.746.542.183.055.394.055.577 0 .213-.064.39-.223.746-.542.969-.868 2.345-2.14 2.894-2.838a2.606 2.606 0 00-.401-3.667c-1.147-.941-2.628-.672-3.528.367z"
      clipRule="evenodd"
    />
  </svg>
);
