import React from "react";
import { IconProps } from "../icon";

export const Send_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 19v-7m.292 7.084l6.978 2.337c.547.183.82.274.99.209a.5.5 0 00.3-.331c.048-.175-.07-.438-.305-.964L12.765 3.622c-.23-.514-.346-.772-.507-.852a.5.5 0 00-.443 0c-.16.079-.277.336-.51.85L3.753 20.336c-.237.526-.356.789-.308.963a.5.5 0 00.3.332c.168.066.442-.025.99-.206l7.052-2.341c.094-.031.14-.047.189-.053a.5.5 0 01.128 0c.048.006.095.022.189.053z"
    />
  </svg>
);
