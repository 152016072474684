import React from "react";
import { IconProps } from "../icon";

export const PhoneHangUpIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5.475 12.863a18.998 18.998 0 01-2.304-5.102c-.16-.564-.24-.846-.241-1.26a3.377 3.377 0 01.384-1.478c.202-.36.467-.624.997-1.154l.157-.158c.532-.531.798-.797 1.083-.941a2 2 0 011.805 0c.286.144.551.41 1.083.941l.195.195c.35.35.524.525.638.7a2 2 0 010 2.18c-.114.177-.289.351-.638.701a2.02 2.02 0 00-.22.238c-.17.238-.228.582-.147.862.023.08.053.142.113.266.121.252.25.5.386.747m2.416 3.218l.045.045a14.605 14.605 0 004.01 2.846c.124.06.187.09.265.113.28.082.625.023.862-.147.067-.048.124-.105.239-.22.35-.349.524-.524.7-.638a2 2 0 012.18 0c.176.114.35.29.7.639l.195.195c.532.531.797.797.942 1.082a2 2 0 010 1.806c-.145.285-.41.55-.942 1.082l-.157.158c-.53.53-.795.794-1.155.997-.4.224-1.02.385-1.478.384-.413-.001-.695-.081-1.26-.241a19.038 19.038 0 01-8.283-4.874L8 16M21 3L3 21"
    />
  </svg>
);
