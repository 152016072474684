import React from "react";
import { IconProps } from "../icon";

export const TagIcon = ({ size = "24px", color = "#e5d2ff", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 11.414C5.37455 11.7898 5.88346 12.0007 6.414 12C6.94454 12.0007 7.45345 11.7898 7.828 11.414L11.414 7.829C11.7892 7.4539 12.0001 6.94507 12.0001 6.4145C12.0001 5.88393 11.7892 5.37509 11.414 5L6.707 0.292999C6.51951 0.10545 6.26519 5.56837e-05 6 -9.53674e-07H1C0.447715 -9.53674e-07 0 0.447714 0 0.999999V6C5.66374e-05 6.26519 0.105451 6.51951 0.293 6.707L5 11.414ZM4.5 6C5.32843 6 6 5.32843 6 4.5C6 3.67157 5.32843 3 4.5 3C3.67157 3 3 3.67157 3 4.5C3 5.32843 3.67157 6 4.5 6Z"
      fill={color}
    />
  </svg>
);
