import React from "react";
import { IconProps } from "../icon";

export const MessageCircle_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 12a9 9 0 01-12.385 8.342c-.2-.081-.3-.122-.38-.14a.909.909 0 00-.219-.024c-.083 0-.173.015-.353.045l-3.558.593c-.373.062-.56.093-.694.035a.5.5 0 01-.262-.262c-.058-.135-.027-.321.035-.694l.593-3.558c.03-.18.045-.27.045-.353a.907.907 0 00-.024-.219c-.018-.08-.059-.18-.14-.38A9 9 0 1121 12z"
    />
  </svg>
);
