import React from "react";
import { IconProps } from "../icon";

export const SpinnerIcon = ({ size = "24px", color = "#d4b6ff", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.04 12c.53 0 .964.43.922.959a12 12 0 1 1-5.707-11.2c.453.277.546.88.234 1.31-.312.428-.91.519-1.367.25a10.08 10.08 0 1 0 4.912 9.64c.05-.528.476-.959 1.006-.959Z"
      fill={color}
    />
  </svg>
);
