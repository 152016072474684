import React from "react";
import { IconProps } from "../icon";

export const MessageSmileCircleIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 14s1.312 1.5 3.5 1.5c2.187 0 3.5-1.5 3.5-1.5m-.75-5h.01M9.75 9h.01m2.74 11a8.5 8.5 0 10-8.057-5.783c.108.32.162.481.172.604a.899.899 0 01-.028.326c-.03.12-.098.245-.232.494l-1.636 3.027c-.233.432-.35.648-.324.815a.5.5 0 00.234.35c.144.087.388.062.876.011l5.121-.529c.155-.016.233-.024.303-.021.07.002.12.009.187.024.069.016.155.05.329.116A8.478 8.478 0 0012.5 20zm3.25-11a.5.5 0 11-1 0 .5.5 0 011 0zm-5.5 0a.5.5 0 11-1 0 .5.5 0 011 0z"
    />
  </svg>
);
